<template>
  <div class="bg-white w-full h-full">
    <section class="w-full h-full">
      <PageHeader
        :heading="$t('xpbx.settings.dids.heading')"
        :showButton="false"
      />

      <!-- DId numbers table -->
      <div class="card relative table-wrapper">
        <DataTable
          ref="dt"
          :value="numbers"
          scrollable
          :scrollHeight="`${scrollHeight}px`"
          tableStyle="min-width: 50rem"
          v-if="!loading"
          :paginator="true"
          :rows="10"
          :filters="filters"
          paginatorPosition="top"
          v-model:selection="selectedRecords"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} numbers"
          selectionMode="single"
          dataKey="id"
          removableSort
          sortMode="multiple"
          v-model:filters="filters"
          filterDisplay="menu"
          :globalFilterFields="['description']"
        >
          <template #empty>
            {{ $t("xpbx.settings.fields.empty_table") }}</template
          >
          <template #header>
            <div
              class="flex flex-wrap gap-2 align-items-center justify-content-between w-full"
            >
              <div>
                <Button
                  :label="$t('xpbx.button.new')"
                  class="mr-2 add-record-button"
                  @click="openNew"
                />
                <Button
                  :label="$t('xpbx.button.delete')"
                  class="delete-button"
                  @click="confirmDeleteSelected"
                  :disabled="!selectedRecord?.id"
                />
              </div>

              <div class="flex gap-2 items-center ml-auto">
                <InputText
                  class="search-input"
                  v-model="filters['global'].value"
                  :placeholder="$t('xpbx.placeholders.search')"
                />
                <Export
                  :dt="dt"
                  :tableData="mappedDidNumbers"
                  :columns="didsTable"
                />
              </div>
            </div>
          </template>
          <Column
            sortable
            field="number"
            style="width: 15%"
            :header="$t('xpbx.settings.dids.fields.phone_number')"
          >
            <template #body="{ data }">
              {{ data?.number }}
              <div>
                <TableActions
                  :data="data"
                  :id="data.id"
                  :isActive="data.is_active"
                  @edit="editHandle"
                  @setActive="setActive"
                  @delete="deleteSelectedRecord"
                />
              </div>
            </template>
          </Column>
          <Column
            sortable
            field="description"
            style="width: 30%"
            :header="$t('xpbx.settings.dids.fields.description')"
          />
          <Column
            sortable
            field="country"
            style="width: 10%"
            :header="$t('xpbx.settings.dids.fields.country')"
          >
            <template #body="slotProps">
              {{ getCountryName(slotProps?.data?.country_id) }}
            </template>
          </Column>
          <Column
            sortable
            field="number_type_id"
            style="width: 15%"
            :header="$t('xpbx.settings.dids.fields.number_type')"
          >
            <template #body="slotProps">
              {{ getNumberType(slotProps?.data?.number_type_id) }}
            </template>
          </Column>
          <Column
            sortable
            field="created_at"
            style="width: 10%"
            :header="$t('xpbx.settings.dids.fields.created_at')"
          >
            <template #body="slotProps">
              <DateItem :value="slotProps?.data?.created_at" />
            </template>
          </Column>
          <Column
            field="updated_at"
            style="width: 10%"
            :header="$t('xpbx.settings.dids.fields.updated_at')"
          >
            <template #body="slotProps">
              <DateItem :value="slotProps?.data?.updated_at" /> </template
          ></Column>
        </DataTable>
        <Loader v-else />
      </div>

      <!-- Dialogs -->
      <Dialog
        v-model:visible="deleteRecordsDialog"
        :style="{ width: '450px' }"
        :header="$t('xpbx.settings.dids.fields.confiirm_delete')"
        :modal="true"
        class="p-fluid relative"
      >
        <div class="confirmation-content">
          <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
          <span class="text-center">{{
            $t("xpbx.settings.dids.notification.confirm_delete", {
              delete: `number ${
                selectedRecord?.number ? selectedRecord.number : ""
              }`,
            })
          }}</span>
        </div>
        <template #footer>
          <Button
            text
            :label="$t('xpbx.button.no')"
            @click="deleteRecordsDialog = false"
          />
          <Button
            text
            :label="$t('xpbx.button.yes')"
            @click="deleteSelectedRecords"
          />
        </template>
      </Dialog>

      <!-- Create dids -->
      <Dialog
        v-model:visible="numberDialog"
        :style="{ width: '450px' }"
        :header="$t('xpbx.settings.dids.dialogs.create_number')"
        :modal="true"
        :dismissableMask="true"
        class="p-fluid relative custom-dialog-heading"
      >
        <div class="field mb-1 select-autocomplete">
          <label for="description mb-2">{{
            $t("label.profile.profileinfo-pwd.body.company-info.country")
          }}</label>
          <SelectWithSearch
            v-model="number.country"
            :options="countryOptions"
            :bordered="true"
            name="country"
            id="country"
            :errors="errors.country"
            :footerLabel="
              $t('label.profile.profileinfo-pwd.body.company-info.country')
            "
          />
        </div>
        <QueueHint :title="$t('xpbx.settings.dids.hints.country')" />

        <!-- Title -->
        <div
          class="field mb-1 mt-4 relative flex justify-start items-start flex-col"
        >
          <label for="channel">{{ $t("xpbx.labels.number_types") }}</label>
          <form-select
            v-model="number.type"
            :options="types"
            name="channel"
            id="channel"
            :errors="errors.type"
            :activeClass="true"
          />
        </div>
        <QueueHint :title="$t('xpbx.settings.dids.hints.type')" />

        <template #footer>
          <DialogButtons
            :saveButtonText="$t('xpbx.button.save')"
            :cancelButtonText="$t('xpbx.button.cancel')"
            @save="create"
            @cancel="numberDialog = false"
          />
        </template>
      </Dialog>

      <!-- Edit dids -->
      <Dialog
        v-model:visible="numberEditDialog"
        :style="{ width: '450px' }"
        :header="$t('xpbx.settings.dids.dialogs.edit_number')"
        :modal="true"
        :dismissableMask="true"
        class="p-fluid relative custom-dialog-heading"
      >
        <FormInput
          id="description"
          :error="errors?.description?.[0]"
          v-model:modelValue="number.description"
          :label="$t('xpbx.settings.dids.labels.description')"
          :hint="$t('xpbx.settings.dids.hints.description')"
        />

        <template #footer>
          <DialogButtons
            :saveButtonText="$t('xpbx.button.save')"
            :cancelButtonText="$t('xpbx.button.cancel')"
            @save="edit"
            @cancel="numberEditDialog = false"
          />
        </template>
      </Dialog>
    </section>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref, onMounted, computed } from "vue";
import PageHeader from "@/modules/xpbx/components/home/UI/PageHeader.vue";
import useNumbers from "@/modules/xpbx/composables/useNumbers";
import Loader from "@/modules/xpbx/components/UI/loader/index.vue";
import DateItem from "@/modules/xpbx/components/UI/DateItem/DateItem.vue";
import { validateCreateNumber } from "@/composables/auth/createNumberValidations";
// Datatable
import Column from "primevue/column";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Dialog from "primevue/dialog";
import Tooltip from "primevue/tooltip";
import DataTable from "primevue/datatable";
import { FilterMatchMode } from "primevue/api";
import validator from "@/composables/auth/validator";
import FormInput from "@/modules/xpbx/components/forms/FormInput.vue";
import Export from "@/modules/xpbx/pages/settings/components/Export/Export.vue";
import DialogButtons from "@/modules/xpbx/components/dialogs/DialogButtons.vue";
import { didsTable } from "@/modules/xpbx/pages/settings/components/table-headers/data.js";
import QueueHint from "@/modules/xpbx/pages/settings/queue-detail/components/QueueHint/QueueHint.vue";
import TableActions from "@/modules/xpbx/pages/settings/components/TableActions/TableActions.vue";

export default {
  name: "DidNumbersTable",
  components: {
    PageHeader,
    Loader,
    DateItem,
    DataTable,
    Column,
    Button,
    InputText,
    Dialog,
    Export,
    FormInput,
    QueueHint,
    TableActions,
    DialogButtons,
  },

  directives: {
    tooltip: Tooltip,
  },

  setup() {
    const store = useStore();
    const {
      findNumbers,
      deleteNumber,
      createNumber,
      updateNumber,
      numbers,
      loading,
      number,
    } = useNumbers();
    const countries = computed(() => store.getters.countries);

    const countryOptions = computed(() => {
      return countries.value.map((country) => {
        return {
          name: country.country_name,
          value: country.id,
        };
      });
    });

    // Datatable
    const dt = ref();
    const isEdit = ref(false);
    const submitted = ref(false);
    const { errors } = validator();
    const scrollHeight = ref(window.innerHeight - 290);
    const numberDialog = ref(false);
    const numberEditDialog = ref(false);

    const selectedRecord = ref(null);
    const selectedRecords = ref([]);
    const deleteRecordsDialog = ref(false);
    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const mappedDidNumbers = computed(() => {
      return numbers.value.map((item) => {
        const country = countries.value.find(
          (country) => country.id === item.country_id
        );
        return {
          ...item,
          country: country?.country_name || "",
          numberType: getNumberType(item.number_type_id) || "Home",
        };
      });
    });

    const getCountryName = (countriID) => {
      if (!countries.value) return "";
      const country = countries.value.find(
        (country) => country.id === countriID
      );
      return country?.country_name;
    };

    const getNumberType = (type) => {
      switch (type) {
        case 1:
          return "Home";
        case 2:
          return "Mobile";
        case 3:
          return "Office";
        default:
          return "Local";
      }
    };

    // Data table functions
    const openNew = () => {
      numberDialog.value = true;
    };

    const closeDialog = (value) => {
      numberDialog.value = value;
    };

    const create = async () => {
      const isValid = validateCreateNumber(number.value);

      if (isValid) {
        const country = countries.value.find(
          (x) => x.country_name === number.value.country
        );

        const countryID = country?.id;

        const formData = {
          number_type_id: +number.value.type,
          country_id: `${countryID}`,
        };

        await createNumber(formData);

        numberDialog.value = false;
      }
    };

    const edit = async () => {
      await updateNumber(number.value, number.value.id);
      numberEditDialog.value = false;
    };

    const confirmDeleteProduct = async (data) => {
      number.value = data;
    };

    const confirmDeleteSelected = () => {
      deleteRecordsDialog.value = true;
    };

    const deleteSelectedRecord = (data) => {
      selectedRecord.value = data;
      deleteRecordsDialog.value = true;
    };

    const deleteSelectedRecords = async () => {
      await deleteNumber(selectedRecord.value.id);
      deleteRecordsDialog.value = false;
    };

    const editHandle = (data) => {
      number.value = data;
      isEdit.value = true;
      numberEditDialog.value = true;
    };

    const setActive = async (data) => {
      const form = {
        number: data.number,
        description: data.description,
        is_active: data.is_active === 1 ? 0 : 1,
      };

      await updateNumber(form, data.id);
    };

    const types = [
      { value: 1, name: "Mobile" },
      { value: 2, name: "Home" },
      { value: 3, name: "Office" },
    ];

    onMounted(async () => {
      await findNumbers();
    });

    return {
      dt,
      types,
      filters,
      loading,
      isEdit,
      didsTable,
      selectedRecord,
      selectedRecords,
      deleteRecordsDialog,
      numberDialog,
      numberEditDialog,
      mappedDidNumbers,
      submitted,
      errors,
      number,
      numbers,
      scrollHeight,
      countryOptions,
      getNumberType,
      getCountryName,
      openNew,
      setActive,
      closeDialog,
      edit,
      create,
      confirmDeleteProduct,
      editHandle,
      confirmDeleteSelected,
      deleteSelectedRecord,
      deleteSelectedRecords,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/data-table.scss";

.select-autocomplete .form-control {
  border: 1px solid #94a3b8;
}
</style>
