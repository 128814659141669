<template>
  <div class="bg-white w-full p-3">
    <DidNumbersTable />
  </div>
</template>

<script>
import DidNumbersTable from "@/modules/xpbx/components/DidNumbersTable/DidNumbersTable";

export default {
  name: "DidNumbers",

  components: {
    DidNumbersTable,
  },
  created() {
    this.$i18n.locale = localStorage.getItem("user_lang");
  },
};
</script>